.download-desk-container {
  width: 346px;
  height: 480px;
  position: absolute;
  left: calc(50vw - 173px);
  top: calc(50vh - 240px);
  z-index: 20;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}
.tip-close-icon {
  margin-top: 10px;
  margin-right: 10px;
}
.download-desk-animation {
  position: absolute;
  left: 25px;
  bottom: -20px;
  z-index: -1;
}
.download-desk-title {
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.download-desk-subtile {
  font-size: 15px;
  opacity: 0.6;
  font-weight: 500;
  text-align: center;
  padding: 20px;
  padding-top: 10px;
  line-height: 17px;
}
.download-desk-feature-item {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  margin: 30px;
  margin-top: 10px;
  text-indent: 2rem;
}
.download-desk-button {
  width: 100px;
  height: 32px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
  margin: 12px 175px;
  position: absolute;
  cursor: pointer;
  z-index: 25;
  border-radius: 16px;
  font-size: 13px;
}
