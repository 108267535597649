.loading-page-container-parent {
  position: absolute;
  top: 115px;
  left: 200px;
  width: calc(100% - 200px);
  height: calc(100% - 90px);
  overflow: hidden;
}
.loading-page-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  user-select: text;
}

.loading-page-box {
  width: 135px;
  height: 190px;
  float: left;
}
.loading-page-cover {
  width: 105px;
  height: 137px;
  opacity: 0.6;
  margin: 15px 15px 37px 15px;
  cursor: pointer;
  animation: changeColor 1s linear infinite;
}
@keyframes changeColor {
  0% {
  }
  100% {
    opacity: 0.67;
  }
}
.loading-page-title {
  height: 30px;
  font-size: 15px;
  opacity: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  word-break: keep-all;
  overflow: hidden;
  position: relative;
  bottom: 35px;
}
.boxcontainer {
  align-items: center;
  width: 100px;
  height: 30px;
  display: -webkit-box;
  display: flex;
  -moz-box-orient: horizontal;
  -webkit-box-orient: horizontal;
}
.loading-page-list-cover {
  float: left;
  width: 57px;
  height: 74px;
  background: #e2e2e2;
  margin-right: 18px;
}
.loading-page-bar1,
.loading-page-bar2,
.loading-page-bar3 {
  height: 16px;
  background: #e2e2e2;
  opacity: 1;
  border-radius: 5px;
  margin: 7px 0px;
}
.loading-page-bar1 {
  width: calc(100vw - 620px);
}
.loading-page-bar2 {
  width: calc(100vw - 320px);
}
.loading-page-bar3 {
  width: calc(100vw - 520px);
}
.loading-page-list {
  height: 74px;
  margin: 10px;
}
.loading-page-bar {
  float: left;
}
