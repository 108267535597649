.book-list-view {
  font-size: 14px;
  clear: both;
  width: 100px;
  z-index: 1;
  height: 20px;
  overflow: hidden;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.1s ease;
}
.view-name {
  display: inline-block;
  width: 60px;
  text-align: center;
}
.card-list-mode {
  line-height: 17px;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
}

.book-list-item-box {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.view-mode-down {
  position: absolute;
  top: 15px;
  right: 15px;
}
