.select-more-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 25px;
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
  width: 150px;
  padding: 5px;
}
.select-more-actions-container {
  display: inline-block;
}
.select-book-action {
  line-height: 20px;
}
