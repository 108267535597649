.delete-digest-button {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  position: absolute;
  top: 2px !important;
  right: 0px !important;
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
}
.delete-digest-icon {
  position: relative;
  float: right;
  font-size: 12px;
  margin-right: 3.5px;
  margin-top: 3px;
  cursor: pointer;
}
