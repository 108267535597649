.popup-box-container {
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  bottom: 0px;
  width: 500px;
  height: 300px;
  left: calc(50% - 250px);
  z-index: 10;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 576px) {
  .popup-box-container {
    width: 90%;
    left: 5%;
  }
}
.popup-menu-triangle-down {
  float: left;
  margin-left: 59px;
  font-size: 16px;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  z-index: 0;
}
.popup-menu-triangle-up {
  transform: rotate(180deg);
  position: absolute;
  bottom: 172px;
  margin-left: 60px;
  font-size: 16px;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  z-index: 0;
}
.delete-highlighter-container {
  position: relative;
}

.popup-close {
  position: absolute;
  top: -30px;
  right: 70px;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
