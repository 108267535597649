.download-desk-title {
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.download-desk-subtile {
  font-size: 15px;
  opacity: 0.6;
  font-weight: 500;
  text-align: center;
  padding: 20px;
  padding-top: 10px;
  line-height: 17px;
}
.download-desk-feature-item {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  margin: 30px;
  margin-top: 10px;
  text-indent: 2rem;
}
.detail-cover {
  max-width: 150px;
  max-height: 230px;
  margin: 10px;
  z-index: 10;
}
.detail-cover-background-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
}
.detail-cover-background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  -webkit-filter: blur(50px);
  -moz-filter: blur(50px);
  -o-filter: blur(50px);
  -ms-filter: blur(50px);
  filter: blur(50px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.detail-cover-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  margin-left: 50px;
  margin-top: 20px;
}
.detail-dialog-book-info {
  overflow-y: scroll;
  height: 185px;
  line-height: 1.25;
  text-align: left;
  overflow-x: hidden;
  width: 100%;
}
.detail-dialog-book-info:hover::-webkit-scrollbar-thumb,
.detail-dialog-book-info:active::-webkit-scrollbar-thumb,
.detail-dialog-book-info:focus::-webkit-scrollbar-thumb {
  display: none;
}
.detail-dialog-book-info::-webkit-scrollbar-thumb {
  display: none;
}
.detail-dialog-book-title,
.detail-dialog-book-author,
.detail-dialog-book-desc {
  font-size: 15px;
  line-height: 18px;
}
.detail-dialog-book-title {
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  line-height: 18px;
}
.detail-sub-info {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
}
.detail-sub-title {
  font-size: 12px;
  opacity: 0.6;
  margin-bottom: 3px;
}
.detail-dialog-book-author {
  margin-bottom: 5px;
  text-align: center;
  opacity: 1;
  line-height: 16px;
}
.detail-sub-content-container {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-sub-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-dialog-book-publisher,
.detail-dialog-book-size,
.detail-dialog-book-page,
.detail-dialog-book-added {
  text-align: center;
  line-height: 16px;
  font-size: 13px;
  max-width: 60px;
  height: 60px;
}

.detail-dialog-book-divider {
  height: 60px;
  width: 0.5px;
  margin-top: 5px;
  opacity: 0.2;
}
.detail-dialog-book-detail {
  /* width: 80%; */
  margin-top: 5px;
  font-size: 15px;
  width: 90%;
  margin-left: 5%;
  text-align: justify;
  hyphens: auto;
}
.detail-dialog-book-desc {
  opacity: 0.6;
  margin-left: 5%;
  margin-top: 10px;
}
.detail-close-button {
}
