.progress-text {
  font-size: 15px;
  width: 100%;
  margin-top: 75px;
  text-align: center;
  height: 25px;
  overflow: hidden;
}
.progress-slide-container {
  width: 209px;
}
.progress-slide-line {
  width: 100%;
  height: 0px;
  border-bottom: 2px solid rgba(112, 112, 112, 1);
  opacity: 1;
  margin-top: 10px;
  margin-left: 52px;
}
.progress-slide-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  left: 100px;
  bottom: 16px;
}
.previous-chapter {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  opacity: 1;
  font-size: 15px;
  transform: rotate(90deg);
  cursor: pointer;
}
.next-chapter {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  opacity: 1;
  font-size: 15px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.previous-chapter-icon {
  line-height: 27px !important;
  margin-left: 5px !important;
}
.next-chapter-icon {
  line-height: 27px !important;
  margin-left: 5px !important;
}
input[type="range"] {
  -webkit-appearance: none;
  width: 200px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 200px;
  height: 0px;
  border-bottom: 2px solid rgba(112, 112, 112, 1);
  opacity: 1;
  cursor: pointer;
}
input[type="range"]::-moz-range-track {
  width: 200px;
  height: 0px;
  border-bottom: 2px solid rgba(112, 112, 112, 1);
  opacity: 1;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 200px;
  height: 0px;
  border-bottom: 2px solid rgba(112, 112, 112, 1);
  opacity: 1;
  cursor: pointer;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(112, 112, 112, 1);
  border-radius: 50%;
  opacity: 1;
  position: relative;
  bottom: 9px;
  cursor: pointer;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(112, 112, 112, 1);
  border-radius: 50%;
  opacity: 1;
  position: relative;
  bottom: 9px;
  cursor: pointer;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(112, 112, 112, 1);
  border-radius: 50%;
  opacity: 1;
  position: relative;
  bottom: 9px;
  cursor: pointer;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}
.progress-panel {
  width: 450px !important;
  height: 100px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  left: calc(50% - 225px);
  z-index: 10;
  animation: fade-up 0.1s ease-in-out 0s 1;
}

#jumpPage,
#jumpChapter {
  width: 30px;
  border: none;
  height: 20px;
  border-radius: 5px;
  padding-left: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
#jumpPage,
#jumpChapter {
  outline: none;
}
